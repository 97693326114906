.carousel-container {
  position: relative;
  margin: auto;
  height: 100%; /* Ensure container takes full height */
  display: flex;
  align-items: center; /* Center items vertically */
}

.carousel-image {
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.carousel-item {
  width: 100%;
  height: 100%; /* Ensure item takes full height */
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  user-select: none;
  transform: translateY(-50%); /* Center vertically */
  z-index: 1; /* Ensure the arrows are above the images */
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev {
  left: 0;
  border-radius: 0 3px 3px 0;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}



/* Caption text */
.carousel-caption-bottom {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 0;
  width: 100%;
  position: absolute;
  bottom: 30px;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}
.carousel-caption-center {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 0;
  width: 100%;
  position: absolute;
  bottom: 50%;
  top: 45%;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.carousel-caption-top {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 0;
  width: 100%;
  position: absolute;
  top: 8px;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}
.dots {
  margin-top: 10px;
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}
.slide-number {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}
